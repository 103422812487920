import React, { useEffect, useState  } from 'react';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'; 
import Sidebar from "./components/sidebar/sidebar";
import Header from './components/header/Header';
import Home from './pages/home';
import AddDetails from './pages/add_person';
import DescriptionPage from './pages/description';
import AdminEditFiles from './pages/admin_edit_files';
import EditFileComponent from './pages/admin_edit_file_id';
import Login from './pages/login';
import MaintenanceMode from "./pages/MaintenanceMode/MaintenanceMode"; 
import { corporateUrl } from '../src/config/config';
import api from '../src/api/api';

function App() { 
  const [isAdmin, setIsAdmin] = useState(true);
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication
  );

  const [maintenanceStatus, setMaintenanceStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [activeIndex,setActiveIndex] = useState(1);

  // Maintenance check on load/refresh
  useEffect(() => {
    const fetchMaintenanceDetails = async () => {
      const tool = 'software';
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.warn('Access token is missing, skipping maintenance check.');
        return;
      }

      try {
        const response = await api.get(`${corporateUrl}/api/get-maintenance-details/${tool}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        
        const isMaintenanceActive = response.data.maintenanceStatus || false;
        setMaintenanceStatus(isMaintenanceActive);

        if (isMaintenanceActive && !location.pathname.includes("/maintenance-mode")) {
          navigate(`/maintenance-mode/${tool}`);
        }
      } catch (error) {
        console.error("Failed to fetch maintenance details:", error);
        setMaintenanceStatus(false);
      }
    };

    fetchMaintenanceDetails();
  }, [navigate, location.pathname]);

  // On maintenance end, redirect to home page if refreshed or on maintenance page
  useEffect(() => {
    // Extract the toolName from the URL path
    const toolName = location.pathname.split("/maintenance-mode/")[1];

    if (!maintenanceStatus && location.pathname.includes("/maintenance-mode")) {
      // If toolName is "corporateportal", redirect to home or login
      if (toolName === 'software') {
        const userId = localStorage.getItem("user_id");
        if (userId) {
          navigate(`/home/${userId}`);
        } else {
          navigate("/login");
        }
      }
      // Otherwise, do not redirect, allow for other tools
    }
  }, [maintenanceStatus, location.pathname, navigate]);

  return (
      <div className="App"> 
        <div className="main-content">
          <div className="body-section">
            <div className="d-flex flex-column flex-xl-row w-100 body-main">
              {!location.pathname.includes("/maintenance-mode") && (
                <Header /> 
              )}
              {authenticated && !location.pathname.includes("/maintenance-mode") && (
                <div className="sidebar-section min-vh-0">
                  <Sidebar activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
                </div>
              )}
              <Routes> 
                <Route path="/" element={<Home />} />
                <Route path="/home/:id" element={<Home />} />
                <Route path="/add_software/:id" element={<AddDetails />} />
                <Route path="/details" element={<DescriptionPage />} />
                <Route path="/all_software/:id" element={<AdminEditFiles />} />
                <Route path="/edit_software/:id" element={<EditFileComponent />} />
                <Route
                path="/login"
                element={<Login setAuthenticated={setAuthenticated} />}
                />
                <Route path="/maintenance-mode/:tool" element={<MaintenanceMode />} />
              </Routes>
            </div>
          </div>
        </div>
      </div> 
  );
}

export default App;
