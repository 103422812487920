import React, { useState, useEffect }from 'react';
import axios from 'axios';
import '../../css/components/header/header.css'
import { corporateUrl } from '../../config/config';

const Header = () => {
  const [userDetails, setDetails] = useState(null);
  const [id, setId] = useState("");
  const fetchDetails = async () => {
    try {
        const token = localStorage.getItem('token'); //retrive the token from the local storage
        const response = await axios.get(`${corporateUrl}/api/user-details/${Number(id)}`, {
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
        });
        setDetails(response.data);
        console.log(response.data)
        localStorage.setItem("access_type",response.data.access_type)
    } catch (error) {
        console.error('Error fetching details:', error);
    }
 };
 useEffect(() => {
    setTimeout(() => {
        setId(localStorage.getItem('user_id'));
    }, 500)
    fetchDetails();
  }, [id]);
  return (
        <div className='banner-div d-flex align-items-center'>
              <div className="main-logo-section">
                <a href="/home/TU0000">
                  <img src='/images/polus.webp' alt="Logo" className="logo" />
                </a>
              </div>
              <div className='banner-title'>
                <span>Software Corner</span>
              </div>
              <div className='profile-label-section'>
                {userDetails && (
                  <div className="user-profile">
                      <div className='profile-image'>
                        {userDetails.profile_pic == ''|| userDetails.profile_pic == null ? 
                          <img  src="/images/profile-user.png" alt="Profile Pic" className='profile-pic'/> 
                        : <img  src={userDetails.profile_pic} alt="Profile Pic" className='profile-pic'/>
                        }
                      </div>
                      <div className="user-info">
                          <span className="user-name">{userDetails.first_name} {userDetails.last_name}</span>
                          <span className="user-role">{userDetails.access_type == "1" ? "Admin" : "Employee"}</span>
                      </div>
                  </div>
                )}
              </div>
        </div>
  
  )
}

export default Header